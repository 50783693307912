import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useState } from "react";
import { IEmployeePortalData } from "../../type/providerDetails";
import "./home.scss";
// import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { ReactComponent as Schedule } from "../../assets/images/images-svg/Schedule.svg";
import { useNavigate } from "react-router-dom";
import { GetAssessmentById } from "../../redux/features/providerForm/form-slice";

const Home = () => {
  // #region variables start

  const dispatch = useAppDispatch();

  //variable to navigation
  const navigate = useNavigate();

  //stores provider details
  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  //stores onBoarding Stages
  const boardingStages = useSelector<RootState, string[]>(
    (state) => state.providerSlice.onBoardingStages
  );

  ///used to handle Dialog
  const [popup, setPopup] = useState<boolean>(false);

  //handles the SavePopup
  const [saveClient, setSaveClient] = useState<boolean>(false);

  //
  const [onBoardingForm, setOnBoardingForm] = useState<string>("");

  // used to handle formurl
  const [formId, setFormId] = useState<string>("");

  //counter for assessment form selection
  const [count, seCount] = useState<number>(0);

  // used to handle form request status
  const [requestStatus, setFormStatus] = useState<string>("");

  // used to handle form type
  const [formType, setFormType] = useState<string>("");

  // #endregion

  // #region useEffect region

  // useEffect(() => {
  //   dispatch(GetProvidersList());
  // }, []);

  //used for fetching provider data
  // useEffect(() => {
  //   if (user) {
  //     dispatch(GetProviderDetails());
  //     dispatch(getProvidersByEmployee());
  //     dispatch(getClientsByEmployee());
  //     dispatch(GetProviderBookingDetails());
  //     if (providerDetails.employmentDetails) {
  //       dispatch(GetAllBoardingStages());
  //     }

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);
  // #endregion

  // console.log("providerDetails:", providerDetails);

  return (
    //   <CurrentMessage/>
    //   <TodaySchedule/>
    <div className="dashboard">
      <h2 className="dashboard__title">
        Welcome {providerDetails?.employmentDetails?.firstName}{" "}
        {providerDetails?.employmentDetails?.lastName}!
      </h2>
      <br />
      <Box className="dashboard__head" display={"flex"}>
        <Schedule />
        <Box>
          <Typography className="MainLabel">
            Currently you are under <span> On-Boarding </span> process with us.
          </Typography>
          {/* <Typography>
            Keep track of your status in the ongoing process
          </Typography> */}
        </Box>
      </Box>
      <Box className="stepperStage">
        <ul>
          {boardingStages?.map((item) => (
            <li
              className={
                item === providerDetails.employmentDetails.onBoardingStage
                  ? "stepperStage_item active-onBoard"
                  : "stepperStage_item"
              }
            >
              <a href="#" className="stepperStage_item-link">
                <span> {item}</span>
              </a>
            </li>
          ))}
        </ul>
      </Box>

      {/* <Box className="Files">
      <Box className="Files_inner">
        <div className="statusAction">Action Needed</div>
        <h3 className="sectionLabel">
          Please Upload these required <span> Files</span>
        </h3>
      </Box>
      <Box className="FilesUpload">
        {providerDetails?.File.map((item: IFiles) => (
          <div className="FilesUpload__inner">
            <div className="FilesUpload__title">{item.fileName}</div>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <div className="FilesUpload__title">
                {moment.utc(item.uploadedAt).format("MM/DD/YYYY")}
              </div>
              <Button>
                <UploadIcon color="primary" />
                Upload
              </Button>
            </Box>
          </div>
        ))}
      </Box>
    </Box> */}

      {/* <Box className="Files">
      <Box className="Files_inner">
        <div className="statusAction">Action Needed</div>
        <h3 className="sectionLabel">please Complete this requested forms</h3>
      </Box>
      <Box className="FilesUpload">
        {providerDetails?.File.map((item: IFiles) => (
          <div className="FilesUpload__inner">
            <div className="FilesUpload__title">{item.fileName}</div>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Button className="pending">pending</Button>
              <Button>
                <UploadIcon color="primary" />
                Upload
              </Button>
            </Box>
          </div>
        ))}
      </Box>
    </Box> */}
      <br />
      <br />
      <br />
      <Grid item xs={12} lg={12}>
        {/* <Box className="Files_inner">
          <h3 className="sectionLabel">
            Onboarding Forms
          </h3>
        </Box> */}

        <Box className="uploadBlock">
          {/* <Typography className="uploadBlock__title">Intake Forms</Typography> */}
          <span
            style={{
              backgroundColor: "#CF3E4A",
              borderRadius: "2px",
              left: "30px",
              padding: "2px 8px",
              gap: "2px",
              color: "white",
            }}
          >
            Action Needed{" "}
          </span>
          <>
            <span style={{ marginLeft: "10px", fontSize: "18px" }}>
              Please complete these <strong>Forms</strong>.
            </span>
            <br />
            <br />
            <br />
            <Box className="intakeForm">
              {providerDetails?.providerForms?.employeePacketForm
                ?.filter(
                  (form) =>
                    form.status === "Started" ||
                    form.status === "Sent" ||
                    form.status === "Saved"
                )
                ?.map((item: any, index: number) => {
                  return (
                    <Box className="intakeForm_inner">
                      <Box className="intakeForm_inner_Box">
                        <div className="content">
                        {item.form.name}
                        </div>
                      </Box>
                      <Box className="intakeForm_bottom">
                        {/* <Jotform /> */}
                        <Button
                          onClick={() => {
                            setPopup(true);
                            setSaveClient(true);
                            if (item.form.url !== null) {
                              setOnBoardingForm(item.form.url);
                            } else {
                              setOnBoardingForm("");
                            }
                            dispatch(GetAssessmentById({ id: item.id }));

                            setFormId(item.id);
                            seCount(index);
                            setFormStatus(item.status);
                            setFormType(item.form.formType);
                          }}
                          className={
                            item.status === "Sent"
                              ? "blueBg"
                              : item.status === "Saved"
                              ? "yellowBg"
                              : item.status === "Mapped"
                              ? "hidebg"
                              : "greenBg"
                          }
                        >
                          {item.status === "Sent"
                            ? "Fill Form"
                            : item.status === "Saved"
                            ? "Need To Complete"
                            : item.status}
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </>
        </Box>
      </Grid>

      {popup &&
        requestStatus &&
        (requestStatus === "Started" ||
          requestStatus === "Saved" ||
          requestStatus === "Sent") && (
          <Dialog open={saveClient} className="smallModel">
            <div>
              <DialogContent>
                You are about to open a new page for this form.{" "}
              </DialogContent>
              <DialogActions>
                <Button
                  className="border-button"
                  variant="contained"
                  onClick={() => {
                    setPopup(false);
                    setSaveClient(false);
                  }}
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  className="button"
                  variant="contained"
                  onClick={() => {
                    setPopup(false);
                    setSaveClient(false);
                  }}
                >
                  <>
                    {onBoardingForm ? (
                      <>
                        <a
                          href={`${onBoardingForm}`}
                          target="_blank"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          Yes
                        </a>
                      </>
                    ) : (
                      <>
                        {/* <p */}
                        <Button
                          className="button"
                          variant="contained"
                          onClick={() => {
                            navigate(`/onboardingform/${formId}`);
                          }}
                        >
                          Yes
                        </Button>
                      </>
                    )}
                  </>
                  {/* )} */}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
    </div>
  );
};

export default Home;
