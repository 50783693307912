import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useUser } from "@descope/react-sdk";
import "./App.css";

import PrivateRoute from "./routes/private.route";
import PublicRoute from "./routes/public.route";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import ScheduleNew from "./pages/schedule/ScheduleNew";
import Document from "./pages/document/Document";
import Task from "./pages/task/Task";
import Message from "./pages/message/Message";

import OnBoardingForm from "./pages/onBoardingForm/OnBoardingForm";

import { useAppDispatch } from "./redux/store";

import {
  GetAllBoardingStages,
  GetProviderBookingDetails,
  GetProviderDetails,
} from "./redux/features/providerSlice";
import {
  getClientsByEmployee,
  getProvidersByEmployee,
} from "./redux/features/listDataSlice";

function App() {
  // const { isLoading } = useAuth();

  //initialize gleap
  /*  useEffect(() => {
    const gleapProjectId = process.env.REACT_APP_GLEAP_PROJECT_ID || "";
    console.log("gleapProjectId:", gleapProjectId);
    if (gleapProjectId?.length > 0) {
      Gleap.initialize(gleapProjectId);
    }
  }, []);
  */

  //used to dispatch
  const dispatch = useAppDispatch();

  //fetches user authentication details
  // const { user } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      dispatch(GetProviderDetails());
      dispatch(getProvidersByEmployee());
      dispatch(getClientsByEmployee());
      dispatch(GetProviderBookingDetails());
      // if (providerDetails.employmentDetails) {
      dispatch(GetAllBoardingStages());
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
        path={"/home"}
      />
      <Route
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
        path={"/"}
      />
      <Route
        element={
          <PrivateRoute>
            <Document />
          </PrivateRoute>
        }
        path={"/documents"}
      />
      <Route
        element={
          <PrivateRoute>
            <Task />
          </PrivateRoute>
        }
        path={"/tasks"}
      />
      <Route
        element={
          <PrivateRoute>
            <Message />
          </PrivateRoute>
        }
        path={"/messages"}
      />
      <Route
        element={
          <PrivateRoute>
            {/* <Schedule /> */}
            <ScheduleNew />
          </PrivateRoute>
        }
        path={"/schedule"}
      />
      <Route
        element={
          <PrivateRoute>
            <OnBoardingForm />
          </PrivateRoute>
        }
        path={"/onboardingform/:id"}
      />

      <Route
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
        path={"/login"}
      />
    </Routes>
  );
}

export default App;
